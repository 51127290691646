<template>
  <div>
    <el-divider content-position="left" class="tittle">Blocks List</el-divider>
    <!-- 表单 -->
    <el-form :inline="true" :model="searchForm" class="demo-form-inline" ref="ruleForm" label-position="left"
      label-width="105px">
      <el-form-item prop="number" id="no" ref="no">
        <el-input v-model="searchForm.number" @keyup.native.enter="onSubmit" placeholder="NO."></el-input>
      </el-form-item>
      <el-form-item prop="tracking_id" id="trackingid" name="changeinput" ref="trackingid">
        <el-input v-model="searchForm.tracking_id" @keyup.native.enter="onSubmit" placeholder="Tracking id"></el-input>
      </el-form-item>

      <el-form-item prop="name" id="name" ref="name">
        <el-input v-model="searchForm.name" @keyup.native.enter="onSubmit" placeholder="Driver name"></el-input>
      </el-form-item>
      <!-- <el-form-item label="NO." prop="number" id="no" ref="no">
        <el-input v-model="searchForm.number" @keyup.native.enter="onSubmit" @focus="focuswidth('no')"
          @blur="blurwidth('no', searchForm.number)"></el-input>
      </el-form-item>
      <el-form-item label="Tracking id" prop="tracking_id" id="trackingid" name="changeinput" ref="trackingid">
        <el-input v-model="searchForm.tracking_id" @keyup.native.enter="onSubmit" @focus="focuswidth('trackingid')"
          @blur="blurwidth('trackingid', searchForm.tracking_id)"></el-input>
      </el-form-item>

      <el-form-item label="Driver name" prop="name" id="name" ref="name">
        <el-input v-model="searchForm.name" @keyup.native.enter="onSubmit" @focus="focuswidth('name')"
          @blur="blurwidth('name', searchForm.name)"></el-input>
      </el-form-item> -->
      <el-form-item prop="status" id="status">
        <el-select v-model="searchForm.status" placeholder="All status" style="width: 100%">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="date">
        <el-date-picker v-model="searchForm.date" type="daterange" range-separator="To" start-placeholder="Start date"
          end-placeholder="End date" value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item class="searchBtn">
        <el-button type="primary" @click="onSubmit">Search</el-button>
        <el-button type="default" @click="resetForm('ruleForm')">Reset</el-button>
        <el-button class="add" @click="createJob">+ CreateBlocks</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="barcode" label="NO." min-width="135">
      </el-table-column>
      <el-table-column label="Driver name" :show-overflow-tooltip="true" min-width="110">
        <template slot-scope="scope">
          <span>{{
            scope.row.driver == null
            ? ''
            : scope.row.driver.first_name + ' ' + scope.row.driver.last_name
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="weight" label="Weight(LB)" min-width="100" align="center">
      </el-table-column>
      <el-table-column prop="dimension" label="Dimension(CU.IN)" min-width="150" align="center">
      </el-table-column>
      <el-table-column prop="status_desc" label="Status" min-width="100">
      </el-table-column>
      <el-table-column label="Shipment list" min-width="125" align="center">
        <template slot-scope="scope">
          <span class="iconfont icon-iconset0266" :style="scope.row.status == 6
                ? 'padding:0 8px;color:#ddd;cursor:not-allowed'
                : 'cursor: pointer;color: #606266; text-decoration: none; padding-right: 8px'
              " @click="golink(scope.row.shipment_list_file, scope.row.status)"></span>
          <span class="iconfont icon-tianjiadingdan1" :style="scope.row.is_edit != 0
            ? 'cursor: pointer;padding:0 8px;'
            : 'padding:0 8px;color:#ddd;cursor:not-allowed'
            " @click="scope.row.is_edit == 0 ? null : editShipment(scope.row.id)"></span>
          <span class="iconfont icon-xie" :style="scope.row.is_edit != 0
            ? 'cursor: pointer;padding:0 8px;'
            : 'padding:0 8px;color:#ddd;cursor:not-allowed'
            " @click="scope.row.is_edit == 0 ? null : delShipment(scope.row.id)"></span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="Created at" min-width="160" align="center">
      </el-table-column>
      <el-table-column prop="sorted_at" label="Sorted at" min-width="160" align="center">
        <template slot-scope="scope">
          <el-button v-if="!scope.row.sorted_at" size="mini" @click="sorted(scope.row.id)">Shipment Sorted
          </el-button>
          <span v-else>{{ scope.row.sorted_at }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="picked_at" label="Driver picked at" min-width="160" align="center">
      </el-table-column>
    </el-table>
    <!-- 修改的弹框 -->
    <el-dialog title="Shipments Add" :visible.sync="editdialog" width="50%">
      <div class="muli-tags" @click="editfocus">
        <div>
          <el-button v-for="(tag, index) in editForm.tracking_ids" :key="index" size="mini" class="id-btn" plain>
            {{ tag
            }}<span class="del" @click="editTag(index)">X</span></el-button>
        </div>
      </div>
      <div class="input-cont">
        <div class="trackid">Tracking Id</div>
        <input type="text" ref="input" v-model="editcurrent" @keyup.enter="checkaddshipment" />
        <el-button type="primary" size="mini" style="margin-left: 10px" @click="addship">Enter</el-button>
        <div class="tip">
          You can enter multiple ids and please press Enter to confirm each one.
        </div>
      </div>
      <!-- 修改弹框操作的按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="sureEdit"
          :disabled="editForm.tracking_ids.length === 0">Submit</el-button>
        <el-button @click="editCancel" size="mini">Cancel</el-button>
      </span>
    </el-dialog>
    <!-- 删除的弹框 -->
    <el-dialog title="Shipments Delete" :visible.sync="deldialog" width="50%">
      <div>
        <div class="muli-tags">
          <div>
            <el-button v-for="(tag, index) in delForm.tracking_ids" :key="index" size="mini" class="id-btn" plain>
              {{ tag
              }}<span class="del" @click="delTag(tag, index)">X</span></el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 创建JOB的弹窗 -->
    <el-dialog title="Create Blocks" :visible.sync="createdialog" width="50%">
      <div class="muli-tags" @click="createfocus">
        <div>
          <el-button v-for="(tag, index) in createForm.tracking_ids" :key="index" size="mini" class="id-btn" plain>
            {{ tag
            }}<span class="del" @click="createTag(index)">X</span></el-button>
        </div>
      </div>
      <div class="input-cont">
        <div class="trackid">Tracking Id</div>
        <input type="text" ref="input" v-model="createcurrent" @keyup.enter="checkcreateshipment" />
        <el-button type="primary" size="mini" style="margin-left: 10px" @click="write">Enter</el-button>
        <div class="tip">
          You can enter multiple ids and please press Enter to confirm each one.
        </div>
      </div>
      <!-- 修改弹框操作的按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="surecreate"
          :disabled="createForm.tracking_ids.length === 0">Submit</el-button>
        <el-button @click="createCancel" size="mini">Cancel</el-button>
      </span>
    </el-dialog>
    <!-- 上一页和下一页 -->
    <div class="btn">
      <el-button type="primary" @click="reduce" :disabled="currentid == 0">previous page
      </el-button>
      <el-button type="primary" @click="add" :disabled="length < 20">next page</el-button>
    </div>
  </div>
</template>

<script>
import {
  joblist,
  addshipment,
  delshipment,
  jobsort,
  delreturn,
  createjobs,
  checkShipment
} from '@/api/user.js'
import local from '../../utils/local'
export default {
  data () {
    return {
      searchForm: {
        date: '',
        name: '',
        status: '',
        number: '',
        tracking_id: ''
      },
      editForm: {
        job_id: '',
        tracking_ids: []
      },
      delForm: {
        job_id: '',
        tracking_ids: []
      },
      createForm: {
        tracking_ids: []
      },
      options: [],
      tableData: [],
      maxid: 0,
      ltid: 0,
      gtid: '',
      currentid: 0,
      length: '',
      isfirst: true,
      currentdata: [],
      editdialog: false, // 弹框显示隐藏
      deldialog: false, // 弹框显示隐藏
      createdialog: false,
      editcurrent: '',
      createcurrent: ''
    }
  },
  created () {
    const statuslist = JSON.parse(local.get('status'))
    for (const key in statuslist) {
      this.options.push({ value: key, label: statuslist[key] })
    }
    this.getlist()
  },
  methods: {
    golink (link, status) {
      // console.log(link)
      if (status === 6) return false
      var tempwindow = window.open('_blank')
      tempwindow.location.href = link
    },
    // focuswidth (fel) {
    //   const focusChild = document.getElementById(fel).children[1]
    //   focusChild.style.width = 190 + 'px'
    // },
    // blurwidth (bel, val) {
    //   if (val) {
    //     return
    //   }
    //   const blurChild = document.getElementById(bel).children[1]
    //   blurChild.style.width = 55 + 'px'
    // },
    // 验证创建tracking id
    async checkcreateshipment () {
      const data = await checkShipment({
        tracking_id: this.createcurrent
      })
      // console.log(data)
      if (data.status === 200) {
        this.create()
      } else {
        this.createcurrent = ''
        this.$message({
          message: data.data.toperr,
          type: 'error'
        })
      }
    },
    // 验证添加tracking id
    async checkaddshipment () {
      const data = await checkShipment({
        tracking_id: this.editcurrent
      })
      // console.log(data)
      if (data.status === 200) {
        this.edit()
      } else {
        this.editcurrent = ''
        this.$message({
          message: data.data.toperr,
          type: 'error'
        })
      }
    },
    write () {
      this.checkcreateshipment()
    },
    // 打开创建JOB
    createJob () {
      this.createcurrent = ''
      this.createdialog = true
      this.createForm.tracking_ids = []
    },
    createfocus () {
      this.$refs.input.focus()
    },

    create (e) {
      // const val = e.target.value
      const val = this.createcurrent
      console.log(this.createcurrent)
      if (!val) return
      // 如果已经存在相同tag，不再添加
      if (this.createForm.tracking_ids.indexOf(val) > -1) {
        this.$message({
          message: 'The tracking id already exists.',
          type: 'error'
        })
        return
      }
      // 把输入值添加到tag，并清空文本框
      this.createForm.tracking_ids.push(val)
      this.createcurrent = ''
    },
    createTag (index) {
      this.createForm.tracking_ids.splice(index, 1)
    },
    createCancel () {
      this.createdialog = false
      this.createcurrent = ''
      this.createForm.tracking_ids = []
    },
    async surecreate () {
      const data = await createjobs({
        tracking_ids: this.createForm.tracking_ids
      })
      if (data.status === 200) {
        this.$message({
          message: 'Created successfully',
          type: 'success'
        })
        this.createForm.tracking_ids = []
        this.createdialog = false
        this.ltid = 0
        this.gtid = ''
        this.currentid = 0
        this.isfirst = true
        this.getlist()
      } else {
        this.$message({
          message: data.data.toperr,
          type: 'error'
        })
      }
    },
    // 打开删除包裹
    async delShipment (delid) {
      const data = await delreturn({
        id: delid
      })
      this.delForm.tracking_ids = data
      this.deldialog = true
      this.delForm.job_id = delid
    },
    // 删除
    delTag (tag, index) {
      // console.log(tag.split())
      this.$confirm('Are you sure you want to delete the shipment?', 'Tips', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          const data = await delshipment({
            job_id: this.delForm.job_id,
            tracking_ids: tag.split()
          })
          // console.log(data)
          if (data.status === 200) {
            this.delForm.tracking_ids.splice(index, 1)
            this.$message({
              message: 'Deleted successfully',
              type: 'success'
            })
          } else {
            this.$message({
              message: data.data.toperr,
              type: 'error'
            })
          }
        })
        .catch(() => { })
    },
    // 打开修改包裹
    editShipment (editid) {
      this.editcurrent = ''
      this.editForm.tracking_ids = []
      this.editdialog = true
      this.editForm.job_id = editid
    },
    // 修改
    editfocus () {
      this.$refs.input.focus()
    },
    addship () {
      this.checkaddshipment()
    },
    edit (e) {
      // const val = e.target.value
      const val = this.editcurrent
      if (!val) return
      // 如果已经存在相同tag，不再添加
      if (this.editForm.tracking_ids.indexOf(val) > -1) {
        this.$message({
          message: 'The tracking id already exists.',
          type: 'error'
        })
        return
      }
      // 把输入值添加到tag，并清空文本框
      this.editForm.tracking_ids.push(val)
      this.editcurrent = ''
    },
    // 删除点击的标签
    editTag (index) {
      this.editForm.tracking_ids.splice(index, 1)
    },
    editCancel () {
      this.editdialog = false
      this.editcurrent = ''
      this.editForm.tracking_ids = []
    },
    // 确认修改
    async sureEdit () {
      const data = await addshipment(this.editForm)
      if (data.status === 200) {
        this.$message({
          message: 'Add successfully',
          type: 'success'
        })
        this.editdialog = false
      } else {
        this.$message({
          message: data.data.toperr,
          type: 'error'
        })
      }
    },
    // 搜索重置
    resetForm () {
      this.searchForm.data = ''
      this.$refs.ruleForm.resetFields()
      this.ltid = 0
      this.gtid = ''
      this.currentid = 0
      this.isfirst = true
      this.getlist()
    },
    // 渲染工作列表
    async getlist () {
      const data = await joblist({
        ltid: this.ltid,
        gtid: this.gtid,
        start_date: this.searchForm.date[0],
        end_date: this.searchForm.date[1],
        driver_name: this.searchForm.name,
        status: this.searchForm.status,
        barcode: this.searchForm.number,
        tracking_id: this.searchForm.tracking_id
      })
      // console.log(data)
      this.tableData = data
      this.length = data.length
      if (data.length > 0) {
        this.ltid = data[data.length - 1].id
        if (this.isfirst) {
          this.maxid = data[0].id
          this.isfirst = false
        }
        // 上一页禁用
        if (data[0].id === this.maxid) {
          this.currentid = 0
        }
      }
    },
    // 查询提交
    onSubmit () {
      this.ltid = 0
      this.gtid = ''
      this.isfirst = true
      this.getlist()
    },
    // 上一页
    reduce () {
      if (this.tableData.length > 0) {
        this.ltid = ''
        this.gtid = this.tableData[0].id
      } else {
        this.gtid = ''
        this.ltid = this.currentdata[0].id + 1
      }
      this.getlist()
    },
    // 下一页
    add () {
      this.currentdata = this.tableData
      this.gtid = ''
      this.currentid = this.ltid
      this.getlist()
    },
    sorted (sortid) {
      this.$confirm(
        'Are you sure the shipment sorting has been completed?',
        'Tips',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )
        .then(async () => {
          const msg = await jobsort({
            job_id: sortid
          })
          if (msg.status === 200) {
            this.$message({
              message: 'Operation successfully',
              type: 'success'
            })
            this.ltid = this.tableData[0].id + 1
            this.gtid = ''
            this.getlist()
          } else {
            this.$message({
              message: msg.data.toperr,
              type: 'error'
            })
          }
        })
        .catch(() => { })
    }
  }
}
</script>

<style lang="less" scoped>
.icon-tianjiadingdan1 {
  font-size: 20px;
}

.icon-iconset0266 {
  font-size: 18px;
}

.add {
  background-color: #009688;
  color: #fff;
}

.el-divider__text.is-left {
  font-size: 30px;
  font-weight: bold;
  color: #333;
}

.tittle {
  margin-bottom: 60px;
}

/deep/.el-table tr th {
  background-color: #f5f7fa;
}

.btn {
  margin-top: 40px;
  text-align: right;
}

.searchBtn {
  margin-left: 20px;
}

.id-btn {
  margin: 0 10px 10px 0;
}

.del {
  margin-left: 3px;
  display: inline-block;
  padding: 2px 3px;
  color: #c0c4cc;

  &:hover {
    background: #f56c6c;
    color: #fff;
  }
}

.del-content {
  display: flex;
  align-items: center;
  margin: 40px 0;
}

.trackid {
  text-align: right;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding-right: 12px;
  width: 88px;
  color: #606266;
}

.input-cont {
  display: flex;
  margin-bottom: 40px;
  box-sizing: border-box;
  position: relative;

  .tip {
    position: absolute;
    top: 50px;
    left: 100px;
    color: #e6a23c;
  }

  input {
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    padding: 0 15px;
    height: 40px;
    outline: none;
    flex: 1;

    &::placeholder {
      color: #c0c4cc;
    }
  }
}

.muli-tags {
  margin-left: 90px;
  padding: 11px;
}

/deep/#no>.el-form-item__label {
  width: 40px !important;
}

/deep/#trackingid>.el-form-item__label {
  width: 90px !important;
}

/deep/#status>.el-form-item__label {
  width: 75px !important;
}

/deep/#name>.el-form-item__label {
  width: 95px !important;
}

/deep/#trackingid>.el-form-item__content,
/deep/#no>.el-form-item__content,
/deep/#name>.el-form-item__content {
  // width: 55px;
  width: 150px;
}

/deep/#status>.el-form-item__content {
  width: 140px;
}

.el-date-editor--daterange.el-input__inner {
  width: 250px;
}

.el-date-editor .el-range-input,
.el-date-editor .el-range-separator {
  font-size: 12px;
}

.el-message-box__wrapper>.el-message-box>.el-message-box__btns>button {
  float: right;
  margin-left: 10px;
}
</style>
